import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './../App.css';
import Navbar from './Navbar';
import Portfolio from './Portfolio';
import Footer from './Footer';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <main className="container">
        <Portfolio />        
      </main>
      <Footer />
    </div>
  );
};

export default App;

import React from 'react';
import Card from './Card';

const projects = [
  {
    title: 'Agenda minimalista',
    description: 'Agenda minimalista que permite guardar información de forma simple, disponible en todo momento.',
    imgSrc: 'imagenes/portafolio.png',
    link: 'https://notas.tecnosweb.com'
  },
  {
    title: 'Editor de estilos',
    description: 'Editor de estilos enfocado a escritores, escritura perfecta.',
    imgSrc: 'imagenes/editor_estilos.png',
    link: 'https://estiloshtml.tecnosweb.com'
  },
  {
    title: 'Diagramas',
    description: 'Software para crear diagramas de manera rápida y elegante.',
    imgSrc: 'imagenes/diagramas.png',
    link: 'https://diagramas.tecnosweb.com'
  },
  {
    title: 'Blog',
    description: 'Información publicada sobre temas tecnológicos.',
    imgSrc: 'imagenes/diagramas.png',
    link: 'https://blog.tecnosweb.com'
  },
  {
    title: 'Pahuatlán',
    description: 'Turismo de Pahuatlán.',
    imgSrc: 'imagenes/diagramas.png',
    link: 'https://pahuatlan.tecnosweb.com'
  },
  {
    title: 'IP Tracker',
    description: 'Obtiene información de la dirección IP actual.',
    imgSrc: 'imagenes/diagramas.png',
    link: 'https://ip-rayonarte.vercel.app'
  },
  {
    title: 'Openproject',
    description: 'Proyectos.',
    imgSrc: 'imagenes/diagramas.png',
    link: 'https://open.tecnosweb.com'
  },
  {
    title: 'Openproject para pruebas',
    description: 'Proyectos.',
    imgSrc: 'imagenes/diagramas.png',
    link: 'https://opentest.tecnosweb.com'
  }
];

const Portfolio = () => {
  return (
    <div className="row">
      <h1 className="text-center mt-5">Bienvenido a mi portafolio</h1>
      <hr className="br-rcustom" />
      <br/><br/>
      {projects.map((project, index) => (
        <div className="col-lg-4 pt-3" key={index}>
          <Card {...project} />
        </div>
      ))}
    </div>
  );
};

export default Portfolio;

import React from 'react';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white border py-3">
      <div className="container-fluid">
        <a className="navbar-brand fw-bold mx-5" href="#"><i className="fas fa-dot-circle" id="tecnos-icon"></i>Tecnos</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item mx-5">
              <a className="nav-link active fw-bold" href="#">INICIO</a>
            </li>
          </ul>
          <span className="navbar-text">¡Hola!</span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
